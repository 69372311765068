@import './assets/fonts/css/animation.css';
@import './assets/fonts/css/first-voices.css';
@import '@bcgov/bc-sans/css/BCSans.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

/* These component rules can be over-ridden using utility classes. 
For example over-ride the colour of the btn-contained backgound using bg-secondary-500 */
@layer components {
  .ql-video {
    @apply w-full h-96;
  }
  .inline-url {
    @apply text-blue-600 visited:text-purple-600 underline underline-offset-2;
  }
  .btn-contained {
    @apply rounded-lg shadow-sm py-2 px-4 inline-flex justify-center items-center space-x-2 text-sm font-medium bg-primary-500 border border-transparent text-white hover:opacity-80 active:opacity-100;
  }
  .btn-outlined {
    @apply rounded-lg shadow-sm py-2 px-4 inline-flex justify-center items-center space-x-2 text-sm font-medium bg-white border border-primary-500 text-primary-500 hover:bg-gray-50 active:bg-white;
  }
  .btn-icon {
    @apply fill-current h-5 w-5;
  }
}

@layer utilities {
  .backface-hidden {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* Necessary to work on certain versions of iOS prior to 17 */
  }
  .rotate-y-180 {
    transform: rotateY(180deg);
  }
}
